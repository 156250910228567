<template>
    <v-card>
        <div class="modal">
            <div class="modal-content" style="width: 100%;">
                <s-toolbar
                    :label="'Impresión de Fotocheck'"
                    dark
                    :color="'#BAB6B5'"
                    close
                    @close="close()"
                    class="mb-6"
                    print @print="downloadPdf()"
                ></s-toolbar>
                <vue-html2pdf
                    :show-layout="false"
                     :float-layout="false"
                    :enable-download="false"
                    :preview-modal="true"
                    :filename="'FOTOCHECK '"
                    pdf-format="a4"
                    pdf-orientation="landscape"
                    pdf-content-width="90%" 
                    :manual-pagination="true"
                    :margin="10"
                    @beforeDownload="beforeDownload($event)"
                    :autoPaging="true"
                    ref="html2Pdf"
                >
                    <section slot="pdf-content">
                  
                      <div v-for="(group, pageIndex) in groupedItems" :key="pageIndex" class="pdf-page" style="height: 100%;">
                         <!-- <div class="pdf-page">-->
                            <!--<div v-for="(item, index) in group" :key="index">-->
                                <!-- Cabecera -->
                             
                            <table style="margin-left: 25px;">
                                <tr style="transform: translateY(30px);"> 
                                    <td v-for="(item, index) in group" :key="index" style="padding: 0; margin: 0 auto;  " >
                                        <div v-if="item.TypeSituation === 3" style="width: 56mm; height:84mm; margin: auto;  border:2px black dotted ;" >
                                            <table style="width: 55.5mm;  height: 83mm; text-align: center; border-collapse: collapse; border: none; padding: 0;" >
                                               
                                                
                                                <tr  style="background-color: #3ea023; height: 20mm;  " >
                                                    <td style="width: 100%; text-align: center;    " >
                                                        <img src="../../../public/static/SUNSHINE40_COMPANY.png" alt="Logo" width="140px" />
                                                    </td>
                                                    
                                                </tr>
                                                <tr style="text-align: center;  height: 30mm; height: 100%;   ">
                                                    <table style="width: 100%; border-collapse: collapse; border: none;" >
                                                                    <tr style="background-color: #3ea023; height: 10mm;  ">
                                                                        <td style=""></td>
                                                                        <td rowspan="2" >
                                                                            <img   style="border-radius: 50%; border: 2px white solid;" height="105" alt="Image preview..."  :src="listImagen2.find((x) => x.dni === item.PrsDocumentNumber).image || '' " >
                                                                        </td>
                                                                        <td ></td>
                                                                    </tr>
                                                                    <tr style="background-color: white; height: 10mm; border-left: 0px white solid; ">
                                                                        <td style=""></td>
                                                                       
                                                                        <td style=" " ></td>
                                                                    </tr>
                                                    </table>
                    
                                                </tr>
                                                                                 
                                                <tr style="background-color: white; height: 20mm;">
                                                    <td style="text-align: center; max-width: 80%; border: 2px solid white; height: 100%; vertical-align: middle;">
                                                        <div style="font-weight: bold; font-size: 12px;">{{ item.NtpName + ' ' + item.NtpSurname }}</div>
                                                        <div style="font-size: 8px;">{{ item.PstName }}</div>
                                                    </td>
                                                </tr>                                            
                                                <tr style="background-color: white; height: 8mm; border: 2px white solid;">
                                                    <td style="text-align: center; max-width: 80%; font-size: 9px; border: 2px white solid;  vertical-align: middle;">
                                                        <div style="font-weight: bold;">{{ item.AreName }}</div>
                                                        <div>DNI: {{ item.PrsDocumentNumber }}</div>
                                                    </td>
                                                </tr>

                                                <tr style="background-color: red ;width: 56mm; height: 5mm; border-color: white; transform: translateX(-1px);">
                                                    <td style="background-color:#ff3d00; width: 100%; color: #ff3d00;">aaa</td>
                                                  
                                                </tr>
                                            </table>
                                        </div>
                                        <!-- Información General -->
                                        <div v-if="item.TypeSituation === 3" style="width: 56mm; border:2px black dotted ;  height:86mm; transform: rotate(180deg);">
                                            <table style="width: 56mm; text-align: center;  height: 85mm; font-size: 9px;  border: none; padding: 0;" >
                                                <div style="width: 80%; margin: auto;">
                                                 
                                                    <tr>
                                                    
                                                        <td style="width: 30%; height: 15%; text-align: center;">
                                                          
                                                            <img src="../../../public/static/SUNSHINE40_sp.png" alt="Logo" width="120px" />
                                                        </td>
                                                    
                                                    </tr>
                                                    <tr >
                                                    
                                                        <td style=" text-align:justify;  text-align-last: center; ">                                                                                       
                                                            {{ item.Information }}       
                                                                                            
                                                        </td>
                                                        
                                                    </tr>
                                                    <tr></tr>
                                                    <tr >                                            
                                                        <td style="">
                                                            
                                                            Planta Tambogrande
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <div style=" max-width: 90%; margin: auto;">
                                                        <td >
                                                            {{ item.AddressCompany }}
                                                        </td>
                                                        </div>
                                                    </tr>
                                                    <tr></tr>
                                                    <tr style="text-align: center; max-width: 80%; vertical-align: middle;">
                                                    (+51) {{ item.TelephoneOne  }} / (+51) {{ item.TelephoneTwo }}
                                                    </tr>
                                                    <tr></tr>
                                                    <tr >
                                                     
                                                          
                                                            <td style=" justify-items: center; padding-left: 30px; padding-right: 30px;">
                                                               
                                                                    <qr-code :size="100"                                                                
                                                                    :text="item.PrsDocumentNumber +
                                                                            ',' +
                                                                            item.TypeCard +
                                                                            ',' +
                                                                            item.CrdVersion +
                                                                            ',' +
                                                                            item.NtpID +
                                                                            ',' +
                                                                            item.NtpSurname +
                                                                            ',' +
                                                                            item.NtpName                                                                 
                                                                    "
                                                                        error-level="H"                                                                        
                                                                    >
                                                                    </qr-code>
                                                                
                                                            </td>
                                                           
                                                                <!--<img  height="75" alt="Image preview..."  :src="personImage" >-->
                                                        
                                                    </tr>
                                                    
                                                    <tr style="text-align: center; max-width: 80%; vertical-align: middle;">
                                                        {{ item.WebSite }}
                                                    </tr>
                                                    
                                                    <tr> V. {{item.CrdVersion}}</tr>
                                                </div>
                                                <tr style="background-color: red ; height: 5mm; width: 55.5mm; transform: translateX(-2px);">
                                                    <td style="background-color:#ff3d00; width: 100%; color: #ff3d00;">aaa</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div v-if="item.TypeSituation === 2" style="width:56mm;  height: 84mm;margin: auto;border:2px black dotted ;  " >
                                            <table style="background-color: #619731   ; width: 55.6mm; height: 83mm; text-align:center; border-collapse: collapse;  color: white; " >
                                                
                                                        <tr style="background-color: white;  text-align: center; height: 42.5mm;" >
                                                        <td style="width: 100%; " colspan="2">
                                                            <tr style="  height: 10mm; ">
                                                                <td style="" rowspan="2" width="50px">
                                                                    <img src="../../../public/static/SUNSHINE40_v.png" alt="Logo"  height="145"   />
                                                                </td>
                                                                <td   colspan="3" >
                                                                    <img   style=" border-color: 5px white solid;" height="145"  alt="Image preview..."  :src="listImagen2.find((x) => x.dni === item.PrsDocumentNumber).image || ''" >
                                                                </td>
                                                                                                                                
                                                            </tr>                                                                    
                                                        </td>  
                                                        <td></td>         
                                                        </tr> 
                                                    <tr>
                                                        <td>                                                                                                                                   
                                                        <tr style="background-color: #619731;  height: 12mm;" >
                                                            <td style="font-weight: bold;font-size: 12px; text-align: center; max-width: 80%;padding: 10px;  ">
                                                                {{ item.NtpName + ' ' + item.NtpSurname}}
                                                            </td>
                                                      
                                                        </tr>
                                                        <tr style="background-color: #619731; height: 8mm;">
                                                            <td style="font-size: 10px; padding: 10px;">
                                                                {{ item.PstName }}
                                                            </td>
                                                           
                                                        </tr>
                                                    
                                                        <tr style="background-color: #619731; text-align: left; font-size: 8px;  height: 12mm;">
                                                            <td style="padding-left: 10px;">
                                                                <tr>
                                                                    <td> DNI : </td>
                                                                    <td style="padding-left: 5px;">{{ item.PrsDocumentNumber }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Área : </td>
                                                                    <td style="padding-left: 5px;">{{ item.AreName }}</td>
                                                                </tr>
                                                            </td>
                                                         
                                                        </tr>
                                                    
                                                    </td>
                                                    <td style="background-color: #ffbb00;color: #ffbb00 ;">aa</td>
                                                </tr>
                                                
                                            </table>
                                        </div>
                                        <!-- Información General -->
                                        <div v-if="item.TypeSituation === 2" style="width: 56mm;  height: 85.6mm; margin: auto; border:2px black dotted ;  ">
                                            <table style=" background-color:  #619731 ;width: 55.6mm; height: 84mm; text-align:center; border-collapse: collapse;   color:white; border: none;  font-size: 8px; ">
                                                
                                                    <tr style=" background-color: #619731; height:70mm ;" >
                                                        <td style="width: 80%;  background-color: #619731;padding-left: 19px; padding-right: 19px;">
                                                            
                                                            <tr style="">
                                                                <td style=" text-align:center;  ">                                                                                       
                                                                    {{ item.Information }}                                                                                                
                                                                </td>                                                                      
                                                            </tr>
                                                            <tr style="font-weight: bold;"><br>Planta:</tr>                    
                                                            <tr>
                                                              
                                                                <td >
                                                                    {{ item.AddressCompany }}
                                                                </td>
                                                                
                                                            </tr>
                                                            <tr></tr>
                                                            <tr style="">
                                                            (+51) {{ item.TelephoneOne  }} / (+51) {{ item.TelephoneTwo }}
                                                            </tr>
                                                            <tr><br>{{ item.Company }}</tr>
                                                            <tr>RUC: 20329725431</tr>
                                                            <tr >  
                                                                    
                                                                    <td style="padding-left: 20px;padding-right: 20px;" >
                                                                          <tr >
                                                                            <qr-code style=" padding: 5px; background-color: white;" :size="75"                                                                
                                                                            :text="item.PrsDocumentNumber +
                                                                                    ',' +
                                                                                    item.TypeCard +
                                                                                    ',' +
                                                                                    item.CrdVersion +
                                                                                    ',' +
                                                                                    item.NtpID +
                                                                                    ',' +
                                                                                    item.NtpSurname +
                                                                                    ',' +
                                                                                    item.NtpName +
                                                                                    ',' +
                                                                                    item.CtrID
                                                                            "
                                                                                error-level="H"                                                                        
                                                                            >
                                                                            </qr-code>
                                                                        </tr>
                                                                    
                                                                    </td>                                                                
                                                                    <!--<img  height="75" alt="Image preview..."  :src="personImage" >-->
                                                                
                                                            </tr>
                                                            <tr style="">
                                                                {{ item.WebSite }}
                                                            </tr>
                                                            <tr > V. {{item.CrdVersion}}</tr>
                                                           
                                                        </td>
                                                        <td style="width: 20%; background-color: white; ">
                                                                                                                                                                                                                                        
                                                            <img src="../../../public/static/SUNSHINE40_v.png" alt="Logo"  height="150px" style="transform: rotate(180deg);"   />
                                                        </td>
                                                    </tr>
                                                    <tr style="background-color: #ffbb00 ;width: 100%; height: 5mm">
                                                        <td style="background-color:#ffbb00; width: 100%; color: #ffbb00;" colspan="2">2222</td>
                                                    </tr>                                                                                              
                                            </table>
                                        </div>
                                      
                                    </td>                                                                    
                                </tr>
                            </table>
                           <!-- </div>-->
                      </div>
                    </section>
                </vue-html2pdf>
            </div>
        </div>
    </v-card>
</template>

<script>
    import moment from 'moment';
    import VueHtml2pdf from 'vue-html2pdf'
    //import jsPDF from 'jspdf';
    import _sHelper from "@/services/HelperService";
    import qrCode from "vue-qr-generator";

    export default{ 
        props:{
            itemsCard: [],
            listImage: []
        },
        components:{
            VueHtml2pdf,
            qrCode
        },
        data: () => ({
           
            SignatureImage: null,
		    SignatureImageSuper: null,
            BossName: "GINO HERNAN BENITES CIEZA",
            items : [],
            listImagen2:[],
            headers : [ 
                { text: "Código Artículo"          , value: 'ArtCode'          },
                { text: "Descripción"              , value: 'DerDescription'   },
                { text: "Unidad de Medida"         , value: 'UnitMeasurement'  },
                { text: "Cantidad"                 , value: 'DerQuantity'      },
                { text: "Centro de Costo"          , value: 'CceName'          },
                { text: "Línea de Negocios"        , value: 'BslName'          },
                { text: "Tipo Costo"               , value: 'TypeCostName'     },
                { text: "Proyecto"                 , value: 'PrjName'          },
            ],
            groupedItems: [],
            personImage:null,
        }),
        methods:{
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
           
            formatDate(date){
                return this.$fun.formatDateTimeShort12HView(date)
                .replace("AM", "a.m.")
                .replace("PM", "p.m.")
            },
            groupItems(items, groupSize = 5) {
                const grouped = [];
                for (let i = 0; i < items.length; i += groupSize) {
                    grouped.push(items.slice(i, i + groupSize));
                }
                console.log("Grupossss",grouped);
                return grouped;
               
            },
            beforeDownload ({ html2pdf, options, pdfContent }) {
				console.log(`On Before PDF Generation`)
				html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages()
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i)
						pdf.setFontSize(10)
						pdf.setTextColor(150)
						pdf.text('Página ' + i + ' de ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                        
					} 
                    const content = this.$refs.html2Pdf.$el.querySelector("#pdf-content");
                    if (content) {
                        // Opcional: Aplicar estilos específicos al contenido antes de descargar
                        content.style.border = "none";
                    }
				}).save()
			},

            groupItemsDynamic(items, maxRowsPerPage = 5) {
                const grouped = [];
                let currentGroup = [];
                let rowCount = 0;

                items.forEach((item) => {
                   // this.getImage(item.PrsDocumentNumber);
                    currentGroup.push(item);                               
                    rowCount++;

                    if (rowCount === maxRowsPerPage) {
                        grouped.push(currentGroup);
                        currentGroup = [];
                        rowCount = 0;
                    }
                });

                if (currentGroup.length > 0) {
                grouped.push(currentGroup);
                }
                console.log("Grupossss",grouped);
               
                return grouped;
            },
            // getImage(document) {
            //     _sHelper.getPhotoWorker(document, this.$fun.getUserID()).then((rsp) => {
            //         const blob = new Blob([rsp.data], {
            //         type: "image/jpg",
            //         });

            //         let image = blob;
            //         var reader = new FileReader();
            //         reader.readAsDataURL(image);
            //         reader.onloadend = () => {
            //             this.personImage = reader.result;
            //             //this.listImagen.push(this.personImage);  
            //             console.log("listado", this.listImagen);                 
            //         };
            //     });            
            // },
            getImage(document) {
                // Llamamos al servicio para obtener la imagen en Base64
                _sHelper.getPhotoWorkerBase64(document, this.$fun.getUserID()).then((rsp) => {
                    if (rsp.status === 200) {
                        // La respuesta 'rsp.data' ya contiene la imagen en Base64
                        this.personImage = 'data:image/jpg;base64,' + rsp.data;  // Asignamos la cadena Base64 directamente

                        // Si deseas usar la imagen, puedes devolverla o simplemente usar 'this.personImage' directamente
                        return this.personImage;
                    } else {
                        console.error("No se pudo cargar la imagen");
                    }
                }).catch((error) => {
                    console.error("Error al obtener la imagen:", error);
                });
            },


            getQr(){
                var imgAll = document.querySelector("img");
                console.log("Image",imgAll);
            },
            close(){
                //this.itemsCard      = [];                
                this.groupedItems   = [];
                this.listImagen2    = [];
                this.listImagen     = [];
                this.$emit('close');
               
            },
            Initialize(){
                this.listImagen2 = this.listImage;
                this.groupedItems= this.groupItemsDynamic(this.itemsCard);
            }
        },
        mounted(){
            console.log("referralGuideSelected", this.itemsCard);
          
        },
        created () {
            console.log("Items Cards", this.itemsCard);
            console.log("Items Images", this.listImage);
            //this.getImage(this.itemsCard[0].PrsDocumentNumber)
            this.listImagen2 = this.listImage;
            this.groupedItems= this.groupItemsDynamic(this.itemsCard);          
            console.log("groupedItems",this.groupedItems);
		},
        watch :{
            itemsCard() {           
                this.Initialize();    
            }
        }
    }
</script>

<style >

.pdf-page {
  height: 210mm; /* Altura exacta de una página A4 */
  width: 297mm; /* Ancho de una página A4 */
  margin: 0 auto;
  position: relative;
  /*margin-bottom: 20px;/* Un margen extra entre páginas para evitar desbordamientos */

  width: 100%;
  height: 100%; /* Garantiza que cada div ocupe una página completa */
  
  max-width     : 297mm;
  min-width     : 297mm;
  max-height    : 210mm;
  min-height    : 210mm;
  
}



.tr {
    page-break-inside: avoid;
    border: none !important;
}

.tbody tr {
    page-break-inside: avoid;
    margin-top: 10px;
    border: none !important;
}

.td {
    border: none !important;
}

.table {
    page-break-inside: avoid;
    border: none !important;
}
.div {
    page-break-inside: avoid;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px;
  margin-top: 10px;
  border-top: 4px double  black;
  border-bottom: 4px double  black;
}


.details-table th {
  border-bottom: 4px double black;
  text-align: center;
  padding: 8px;
}

.details-table td{
    text-align: center;
    padding: 8px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


.modal-content {
  background-color: #fff;
  padding: 20mm; /* Márgenes internos */
  border: 1px solid #000;
}


</style>